const multiLangText = {
    en: {
        all_products: 'All products',
        language: 'Language',
    },
    vn: {
        all_products: 'Tất cả sản phẩm',
        language: 'Ngôn ngữ',

    }
}

export default multiLangText