export function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="slickPrevArrow"
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: '20px', height: '20px' }}
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    );
}

export function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="slickNextArrow"
            onClick={onClick}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: '20px', height: '20px' }}
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    );
}