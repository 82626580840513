// Import FirebaseAuth and firebase.
import React, { useEffect, useState, useContext } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { globalContext } from '../contexts/globalContext';
import { Link, Navigate } from 'react-router-dom';
import { webName } from '../config';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};
/* login xong nó ko redirect */
function AuthGuard({children}) {
  const {user} = useContext(globalContext)
  if (!user) {
    return (
      <div className='authPage'>
        <div style={{paddingTop:'30px', paddingBottom:'150px', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <div className='neonText' >
            <div>Welcome to</div>
            <div style={{marginTop:'12px'}}><Link to='/' className='header_logo'>{webName}</Link></div>
          </div>
        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }

  if(!children){
    return <Navigate to='/' />;
  }else{

  return (
    // <div>
    //   <h1>My App</h1>
    //   <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
    //   <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
    // </div>
    <div>{children}</div>
  );
  }
}

export default AuthGuard;